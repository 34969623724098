import { type RouteObject } from 'react-router-dom'

export enum Pages {
  UNKNOWN = 'unknown',
  DASHBOARD = 'dashboard',
  APP = 'app',
  GALLERY = 'gallery',
  EXPLORE = 'explore',
}

// The order matters, the first route that matches gets returned.
// e.g. if /:owner/:repo is listed before /datasets/:id, it will match instead.
// The routes are ordered as follows:
// 1. Routes with no path variables, ordered alphabetically
// 2. Routes with path variables, ordered based on match priority
export enum Routes {
  // 1. Routes with no path variables, ordered alphabetically
  CREATE_FROM_FORK = '/create-from-fork',
  CREATE_FROM_TEMPLATE = '/create-from-template',
  CREATE_TEMPLATE_FROM_APPID = '/create-from-template/:appId',
  DEPLOY = '/deploy',
  DEPLOY_SAMPLE_APP = '/deploy-sample-app',
  ERROR = '/error',
  ERROR_NEW_LOGIN = '/errors/new_login',
  ERROR_NOT_FOUND_OR_NOT_AUTHORIZED = '/errors/not_found',
  EXPLORE = '/explore',
  EDIT_WITH_CODESPACES = '/edit-with-codespaces',
  FORK = '/fork',
  FORK_SAMPLE_APP = '/fork-sample-app',
  GALLERY = '/gallery',
  LOGIN = '/login',
  LOGIN_REQUIRED = '/errors/login-required',
  NEW_APP = '/new',
  NEW_APP_CHOOSE_TEMPLATE = '/new/choose-template',
  NEW_APP_DEPLOY_FROM_TEMPLATE = '/new/deploy-from-template',
  NEW_APP_DEPLOY_FROM_TEMPLATE_APPID = '/new/deploy-from-template/:templateId',
  PROFILE_PAGE = '/user/:userName',
  REDIRECT = '/redirect',
  SIGNUP = '/signup',
  TEMPLATE_PREVIEW_ID = '/template-preview/:templateId',
  UPDATED_EMAIL = '/updated-email',

  // 2. Routes with path variables, ordered based on match priority
  APP_PLACEHOLDER_WITH_OPTIONALS = '/app/:subdomain/*',
  APP = '/:owner/:repo',
  APP_WITH_OPTIONALS = '/:owner/:repo/*',
  ROOT_WITH_OPTIONALS = '/*',
  ROOT = '/',
  DASHBOARD = '/',
}

export enum ServerPaths {
  DOMAIN_AUTH = '-/auth/app',
  SUBDOMAIN_LOGOUT = '-/logout',
}

export interface AppSections<T> {
  [Pages.APP]: T
  [Pages.DASHBOARD]: T
}

export type AppCodeSplit = AppSections<RouteObject[]>

export const workspaceQueryParameterName = 'workspaceName'
export const redirectURIQueryParameterName = 'redirect_uri'

export default Routes
