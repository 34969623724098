import { type ImgHTMLAttributes, useEffect, useState } from 'react'

interface Props extends ImgHTMLAttributes<any> {
  srcFallback: string
  srcSetFallback?: string
}

export default function ImageWithFallback({
  src,
  srcFallback,
  srcSet,
  srcSetFallback,
  alt,
  ...props
}: Props): JSX.Element {
  const [shouldFallback, setShouldFallback] = useState(false)

  useEffect(() => {
    setShouldFallback(false)
  }, [src, srcSet])

  const onError = (): void => {
    setShouldFallback(true)
  }

  return (
    <img
      src={shouldFallback ? srcFallback : src}
      srcSet={shouldFallback ? srcSetFallback : srcSet}
      onError={onError}
      alt={alt}
      {...props}
    />
  )
}
