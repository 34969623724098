import { memo, type ReactElement, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { SIZE, StyledSpinnerNext as Spinner } from 'baseui/spinner'

import useUser from 'api/user'
import { useGitHubLogin } from 'api/login'
import { useLogout } from 'api/logout'

import { useWorkspaceContext } from 'context/workspaceContext'
import { select as selectWorkspaceAction } from 'reducers/workspace'

import LinkButton from 'components/linkButton'

import { ReactComponent as IconCheck } from 'assets/svg/icon_check.svg'
import { ReactComponent as IconConnectAccount } from 'assets/svg/icon_connectaccount.svg'
import { ReactComponent as IconLinkOut } from 'assets/svg/icon_link_out_arrow.svg'

import styles from './styles.module.scss'
import { type Dispatch } from 'redux'
import { type AnalyticsPayload, showModal } from 'reducers/modal'
import { warningInfoSelector } from 'reducers/warningInfo'
import { ReactComponent as IconWarning } from 'assets/svg/icon_warning_rounded.svg'
import { ReactComponent as WorkspacesArrow } from 'assets/svg/workspaces_arrow.svg'
import { Link as StreamlitLink } from 'components/link'
import urls from 'constants/urls'
import ImageWithFallback from 'components/imageWithFallback/ImageWithFallback'

interface Props {
  buttonClassName?: string
  hideUserSettings?: boolean
  hideAnalytics?: boolean
}

interface WorkspaceAvatarProps {
  name?: string
  label?: string
}

function WorkspaceAvatar({ name, label }: WorkspaceAvatarProps): ReactElement {
  return (
    <ImageWithFallback
      src={`https://github.com/${name}.png?s60`}
      srcFallback={urls.defaultGithubUserImageUrl}
      alt={label ?? 'Workspace avatar'}
    />
  )
}

function WorkspaceSwitcher({
  buttonClassName,
  hideUserSettings,
  hideAnalytics,
}: Props): ReactElement | null {
  const { user, workspaces } = useUser()
  const {
    doLogin,
    doRedirect,
    redirectUrl,
    pending: pendingLogin,
  } = useGitHubLogin()
  const { doLogout } = useLogout()
  const dispatch = useDispatch()
  const currentWorkspace = useWorkspaceContext().Name()
  const warningInfo = useSelector(warningInfoSelector)

  useEffect(() => {
    if (!redirectUrl) return

    doRedirect()
  }, [redirectUrl, doRedirect])

  const onClickSettings = useCallback(() => {
    dispatch(
      showModal({
        name: 'USER_SETTINGS',
      }),
    )
  }, [dispatch])

  if (!user) return null

  const isSelected = (workspace: string): boolean => {
    if (currentWorkspace) return currentWorkspace === workspace

    return workspace === user.login
  }

  const currentName = currentWorkspace || user.login

  return (
    <StatefulPopover
      placement={PLACEMENT.bottomRight}
      content={({ close }) => (
        <div className={styles.switcherContainer}>
          <ul className="border-b border-solid border-black-300">
            {!hideUserSettings && (
              <li>
                <button
                  className="space-x-2"
                  onClick={() => {
                    onClickSettings()
                    close()
                  }}
                >
                  <span>Settings</span>
                  {!!warningInfo?.warningText && (
                    <IconWarning className="w-4 h-4" />
                  )}
                </button>
              </li>
            )}
            <li>
              <StreamlitLink external to="https://docs.streamlit.io/">
                <button className="gap-2">
                  <span>Docs</span>
                  <IconLinkOut className={styles.iconArrow} />
                </button>
              </StreamlitLink>
            </li>
          </ul>
          <ul>
            {workspaces?.map(({ name }) => (
              <li key={name}>
                <button
                  aria-label={`Switch to ${name}'s workspace`}
                  className={classNames({
                    [styles.selected]: isSelected(name),
                  })}
                  onClick={() => {
                    dispatch(
                      selectWorkspaceAction({
                        workspace: name,
                      }),
                    )
                    close()
                  }}
                >
                  <WorkspaceAvatar name={name} />
                  <span>{name}</span>

                  {isSelected(name) && (
                    <span className={styles.iconCheck}>
                      <IconCheck />
                    </span>
                  )}
                </button>
              </li>
            ))}
            {!user?.login && (
              <li className={styles.connectAccountBtn}>
                <button
                  onClick={async () => {
                    await doLogin()
                  }}
                >
                  <IconConnectAccount />

                  <span>Connect GitHub account </span>

                  {pendingLogin && (
                    <span className={styles.spinner}>
                      <Spinner $size={SIZE.small} />
                    </span>
                  )}
                </button>
              </li>
            )}
          </ul>

          <div className={styles.signOutContainer}>
            <LinkButton
              className={styles.signOutBtn}
              data-testid="sign-out-button"
              onClick={async () => {
                await doLogout(false)
              }}
            >
              Sign out
            </LinkButton>
            <ul className={styles.connectedAccounts}>
              {user?.ssoEmail && <li>{user.ssoEmail}</li>}
              {user?.login && <li>github.com/{user.login}</li>}
            </ul>
          </div>
        </div>
      )}
    >
      <button
        className={buttonClassName}
        aria-label={currentName ? `${currentName}'s account` : 'Account'}
      >
        {currentName && (
          <WorkspaceAvatar
            name={currentName}
            label="Current workspace avatar"
          />
        )}
        <div className="flex flex-row gap-4">
          <span className={styles.WorkspaceName}>
            {currentName || 'Workspaces'}
          </span>
          <div className="flex flex-row gap-1 items-center">
            {warningInfo?.warningText && <IconWarning className="w-6 h-6" />}
            <WorkspacesArrow />
          </div>
        </div>
      </button>
    </StatefulPopover>
  )
}

export function openAnalyticsModal(
  dispatch: Dispatch<any>,
  modalPayload?: AnalyticsPayload,
): void {
  dispatch(
    showModal({
      name: 'ANALYTICS',
      modalPayload,
    }),
  )
}

export default memo(WorkspaceSwitcher)
